import React, { useContext, useEffect, useRef } from "react";
import { Section, Text } from "../../../components/Core";
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from "react-bootstrap";
import GlobalContext from "../../../context/GlobalContext";
import HeroSupportListView from "../../../components/Rebrand/Hero/HeroSupportListView";
import styled from "styled-components";
import HeroSupportDetails from "../../../components/Rebrand/Hero/HeroSupportDetails";
import { getProperty } from "./../../../utils/helperFn";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from 'react-markdown'

const gfm = require('remark-gfm')

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
`

const SupportDetailView = (props) => {

  const data = props.data ? getProperty(props, 'data') : {}
  const articleData = getProperty(data, 'strapiFaqArticles')
  const postCategory = getProperty(articleData, 'faq_category')

  const gContext = useContext(GlobalContext);

  return (
    <>
      <Helmet>
        <title>{articleData?.title}</title>
        <meta name="title" content={articleData?.title}/>
        <meta name="description" content={articleData?.title} />
      </Helmet>
      <HeroSupportDetails articleData={articleData} postCategory={postCategory}/>
      <Section>
        <Container>
          <Row>
            <Col s m={12}>
              <ReactMarkdown plugins={[gfm]} className="supportArticleContent" source={articleData.content} />
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

/**
 * vote_up
 * vote_down
 */
export const data = graphql`
  query getArticleForId($article_slug: String) {
    strapiFaqArticles(article_slug: {eq: $article_slug}) {
      id
      article_slug
      featured
      content
      title
      faq_category {
        page_slug
        title
      }
    }
}`

export default SupportDetailView