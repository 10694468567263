import React from "react";
import styled from "styled-components";
import { Container, Col, Row } from "react-bootstrap";
import { Input, Section, Text } from "../../Core";
import { Link } from "gatsby";
import SearchKnowledgeBase from "../SearchKnowledgeBase/SearchKnowledgeBase";

const StyledSection = styled(Section)`
  background-color: #08DE9E;
  h2 {
    color: #000;
  }
`

const Breadcrumbs = styled.div`
  a {
    text-decoration: none;
    font-size: 1rem;
    margin-left: 4px;
  }
`

const HeroSupportDetails = (props) => {
    return (
        <StyledSection>
            <Container>
                <Row>
                  <Col sm={12}>
                    <Row>
                      <Col sm={7}>
                        <SearchKnowledgeBase />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Text>
                          <Breadcrumbs>
                            <a href="https://www.youfibre.com/">YouFibre</a>
                            <Link to="/knowledge-base/">» Knowledge Base</Link>
                            <Link to={`/knowledge-base/articles/${props?.postCategory?.page_slug}`}>» {props?.postCategory?.title}</Link>
                            <Link to={`/knowledge-base/article/${props?.articleData?.article_slug}`}>» {props?.articleData?.title}</Link>
                          </Breadcrumbs>
                        </Text>
                      </Col>
                    </Row>
                    <h3 className="mt-5 mb-0 pb-0">{props?.articleData?.title}</h3>
                  </Col>
                </Row>
            </Container>
        </StyledSection>
    )
};

export default HeroSupportDetails;
